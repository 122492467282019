@import '~theme/viewport.module.css';

.SearchMenuContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);

  @media (--viewport-lg) {
    flex-direction: row;
  }
}

.SearchMenuContent-cardWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
}

.SearchMenuContent-divider {
  @media (--viewport-lg) {
    border-right: 1px solid var(--color-neutral-100);
    padding-right: var(--space-lg);
  }
}
