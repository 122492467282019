.CardItem {
  display: flex;
  height: 100%;
}

.CardItem-content {
  align-items: center;
  display: flex;
  gap: var(--space-md);
}

.CardItem-contentStyleDisabled {
  cursor: default;
  opacity: 0.6;
}

.CardItem-icon {
  width: 56px;
}

.CardItem-header {
  align-items: center;
  display: flex;
}

.CardItem-chevron {
  font-size: 16px;
}
